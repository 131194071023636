// create an Observer instance
const resizeObserver = new ResizeObserver(entries => {
    if (window.innerWidth > document.body.clientWidth) {
         document.body.classList.add("has-scroll");
    } 
    else {
        document.body.classList.remove("has-scroll");
    } 
})
// start observing a DOM node
resizeObserver.observe(document.body); 

//commented zohoBOT for admin portal

//var $zoho = $zoho || {};
//$zoho.salesiq = $zoho.salesiq || {
//  widgetcode:
 //   "494d841d28dfb37357496be7dc8f3b036483edb01e5ae637b0c8a265a292a958a930d77a6b71ea818c1eb197cfb5aade",
 // values: {},
 // ready: function () { },
//};
//var d = document;
//s = d.createElement("script");
//s.type = "text/javascript";
//s.id = "zsiqscript";
//s.defer = true;
//s.src = "https://salesiq.zoho.com/widget";
//t = d.getElementsByTagName("script")[0];
//t.parentNode.insertBefore(s, t);
//    $zoho.salesiq.ready = function () {
        // add()
 //  };


//  function add(x, y) {
//    console.log(x)
//     $zoho.salesiq.visitor.info({ id: x, phoneNum: y });
//   }


//  window.add = function(x) {
 //   $zoho.salesiq.visitor.info({ id: x.Id, phoneNum: x.InternationalMobileNumber });
 // }
